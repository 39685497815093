import { mainSchool } from '@/helpers/const/AppData'
import { isShowcaseDomain } from '@/helpers/domain'
import { insertTrackersInDOM } from '@/helpers/trackers'
import { loadScript } from '@/helpers/trackers/loadScript'
import { loadScriptByText } from '@/helpers/trackers/loadScriptByText'
import { scriptMailRu } from '@/helpers/trackers/scriptMailRu'
import { scriptsBySchoolId } from '@/helpers/trackers/scripts'
import ApiSubdomainList from 'ApiRest/Api/School/UnionSubDomain'

const getSchoolId = async () => {
  const domain = window.domains.main
  let { id } = mainSchool

  try {
    const { data: subdomainsList = [] } = await ApiSubdomainList.get(domain)

    if (subdomainsList.length) {
      const school = subdomainsList.find((subdomain) => subdomain.self)

      if (school) {
        id = school.schoolId
      }
    }

    return id
  } catch (error) {
    return null
  }
}

export const initTrackers = async () => {
  const shouldLoadTrackers = process.iblsEnv.IS_PRODUCTION

  if (shouldLoadTrackers) {
    let data

    if (isShowcaseDomain) {
      loadScriptByText(scriptMailRu, false)

      data = {
        metrika: {
          id: 91075504,
          init: {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
            ecommerce: 'dataLayer',
          },
        },
        idAnalytics: 'G-JG6CERCZRV',
      }
    } else {
      const schoolId = await getSchoolId()

      data = scriptsBySchoolId[schoolId]
    }

    if (data && Object.keys(data).length > 0) {
      insertTrackersInDOM(data)
    }
  }

  // Аналлитика IAS
  if (
    process.iblsEnv.IBLS_TRACKER_DOMAIN &&
    process.iblsEnv.IBLS_TRACKER_SCRIPT
  ) {
    loadScript(
      `${process.iblsEnv.IBLS_TRACKER_DOMAIN}/${process.iblsEnv.IBLS_TRACKER_SCRIPT}?${Date.now()}`,
    )
  }
}
